import React, {useState, useEffect, useContext} from 'react'
import ReactDOM from 'react-dom'
import {useRoutes, usePath, navigate} from 'hookrouter'
import useFetch, {Provider} from "use-http"

import {    
    isMobile
} from "react-device-detect";

import {Image, Button, Form, Input, Layout, Menu, Modal, Popconfirm} from 'antd'
import {   
    LogoutOutlined,
    LoginOutlined,   
    LockOutlined,
    MailOutlined,    
    UserOutlined,   
    EyeInvisibleOutlined,
    EyeTwoTone

} from '@ant-design/icons'
//import './style.css'
import 'antd/dist/antd.less';

import routes from "./router"
import Store, {Context} from "./store"
import {errorN, auth} from "./general";



const {Header, Content} = Layout;
const {SubMenu} = Menu;


function Login(props) {

    const [state,dispatch] = useContext(Context)
    const [visible,setVisible] = useState(true);
    const [failed,setFailed] = useState(false);
    const [notice, setNotice]= useState({});
   
    const [form] = Form.useForm()
 
    useEffect(() => {       

        if(localStorage.getItem('session') === null || localStorage.getItem('session') === undefined)
        {           
            setVisible(true);
            navigate('/')
        }
        else
        {
            setVisible(false);
            dispatch({type:'USER_NAME',payload: localStorage.getItem('name')});
            navigate(`/${localStorage.getItem('page')}`);
        }

        return () => {     
        }
    },[state.username, visible,dispatch])

    async function loginMe(data) {

        
        let flg = false;
        let name = '';
        for(let i of auth)
        {            
            if(i.username === data.username && i.password === data.password)
            {
              flg = true;
              name = i.name;
            }            
        }

        if(flg === true)
        {
            localStorage.setItem('name', name);
            localStorage.setItem('session', 'true');               
                            
            localStorage.setItem('page', 'dashboard');

            dispatch({type:'USER_NAME',payload: name});

            navigate(`/${localStorage.getItem('page')}`);

            form.resetFields();
            setFailed(false);
            setVisible(false);
        }
        else
        {
            setFailed(true);
            setNotice(`INVALID USER. Please contact the administrator.`);  
        }
        
    }

    const onSubmit = (e) => {
        //e.preventDefault();

        form.validateFields().then(values => {

            const {username, password} = values;
            const sendDetails = {
                username,
                password
            }
            loginMe(sendDetails).then(r => {});
        })
    };

    const onKeyPress = (e) => {
        if(e.which === 13) {
            onSubmit();
        }
    }


    return (
        <>            
            <Modal
                visible={visible}
                title='Login'              
                closable = {false}                
                footer =""                
                width={isMobile === true ? '100%':'25%'}
            >
                <Form

                    id="modalForm"
                    name="basic"
                    initialValues={{
                        remember: true
                    }}                   
                    form={form}
                >
                    <>
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input onKeyPress={onKeyPress} prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Username" />

                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password
                                onKeyPress={onKeyPress}
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                            />
                        </Form.Item>                      
                        
                    </>                                       

                    {failed === false ? '' : <><div style={{ color: 'red', paddingBottom: '15px' }}>{'Login Failed! ' + notice }</div></>}

                    <Form.Item>
                    <>
                        <div>                                
                            <Button type="primary" onClick={onSubmit}>
                                <LoginOutlined /> SIGN IN
                            </Button>
                            &nbsp;                                        
                            &nbsp;&nbsp;&nbsp;
                        </div>                                  
                    </>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

function Main(props) {
    const routeResult = useRoutes(routes)
 
    const path = usePath()
    const [state,dispatch] = useContext(Context);  

    const [sessionOn, setSessionOn] = useState(false);
    

    async function logoutMe() {        

        localStorage.setItem('page', '');
            navigate('/');

            localStorage.removeItem('session');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            localStorage.removeItem('cookieexpiry');
            localStorage.removeItem('role');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('properties');
            localStorage.removeItem('currentproperty');
            localStorage.removeItem('dashboardstyle');

            localStorage.removeItem('emailsetting'); 
            localStorage.removeItem('noticesetting'); 
            localStorage.removeItem('folionotice'); 
            localStorage.removeItem('receiptnotice'); 
            localStorage.removeItem('foliofooter'); 
            localStorage.removeItem('timesetting'); 
            localStorage.removeItem('systemparametersetting'); 
            localStorage.removeItem('currencycode'); 
            localStorage.removeItem('channelsetting'); 
            localStorage.removeItem('feesetting'); 
            localStorage.removeItem('devicesetting'); 
            localStorage.removeItem('guestparametersetting'); 
            localStorage.removeItem('customsetting'); 
            localStorage.removeItem('unitcustomname'); 
            localStorage.removeItem('fullpaymentbeforeinvoice'); 

            localStorage.removeItem('countrylist'); 
            localStorage.removeItem('currencylist'); 
            localStorage.removeItem('taxlist'); 

            localStorage.removeItem('roomlist'); 

            
            dispatch({type:'USER_SESSION',payload: false});
            dispatch({type:'USER_USERNAME',payload: ''});
            dispatch({type:'USER_NAME',payload: ''});
            dispatch({type:'USER_ROLE',payload:''});
            dispatch({type:'USER_ID',payload:0});
            dispatch({type:'USER_EMAIL',payload:''});
            dispatch({type:'USER_PROPERTIES',payload:[]});
            dispatch({type:'USER_CURRENTPROPERTY',payload:{}});
            dispatch({type:'USER_DASHBOARDSTYLE',payload:'floor'});
    }

    useEffect(() => {

        if(localStorage.getItem('session') === null)
        {
            setSessionOn(false);
        }
        else
        {
            setSessionOn(true);
        }

               
        return () => {

        }
    },)

    return (
        <Layout style={{ height: "100vh"}}>
        
            
            <Header  className="site-layout-background" style={{ backgroundImage:`url("/header-bg.png")`, padding: 0, color:'white', fontSize: isMobile === false ? '20px' : '16px',  width: '100%'}} >
                &nbsp;&nbsp;<img src={'/moonosys48.png'} alt="" style={{float:'left', paddingTop: '10px', paddingLeft: '15px'}} />&nbsp;&nbsp;{`Ratri Property Management System - Download Center`} 
                {state.session !== undefined && state.session !== false ?
                null
                :
                <Login mode={'login'}/>
                }
            </Header>

           
            {sessionOn === true ? 
                <Menu theme="dark" selectedKeys={path.split('/')[1]} mode="horizontal">     
                <SubMenu key="subx" icon={<UserOutlined  />} title={`Hi ${state.name === undefined || state.name === '' ? 'Guest' : state.name}!`}>
                        
                        <Menu.Item key="6" icon={<LogoutOutlined/>}>
                            <Popconfirm
                                title="Are you sure want to Logout?"
                                onConfirm={() => {logoutMe().then(r => {})}}
                                /*onCancel={}*/
                                okText="Yes"
                                cancelText="No"
                            >
                                {/*<Button type="primary" style={{width: '100%'}}>LOGOUT</Button> */}
                                LOGOUT
                            </Popconfirm>
                        </Menu.Item>

                    </SubMenu>    
                </Menu>

            :
                <Image
                width={'100%'}
                src='/images/wallpage.jpg'
                preview={false}
                />
            }
   
            <Layout className="site-layout" >
                
                {/*<Content style={{margin: '0 16px'}}>*/}
                <Content style={{marginLeft:'0px'}}>
                    {routeResult}
                </Content>
                {/* <Footer style={{position: "sticky", textAlign: 'center', backgroundColor: '#1F1F1F'}}> ©2021 Managed by Ratri Cloud</Footer> */}
            </Layout>
        </Layout>
    );

}

let options = {
    cachePolicy: 'no-cache', interceptors: {
        response: async ({response}) => {
            //window.location.href
           /* console.log('kenya1',response.status )
            if(response.status === 401) {


            }*/

            return response
        }
    }
}
ReactDOM.render(
    <Store>
        <Provider options={options}>
            <Main/>
        </Provider></Store>, document.querySelector('#root'))
