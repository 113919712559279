import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Table,Form, Card,Layout,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';
import {navigate} from 'hookrouter'

import useFetch from "use-http";
import {    
  CloudDownloadOutlined    
} from '@ant-design/icons'



const {Content} = Layout;
const { Meta } = Card;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Dashboard(props) {
 
   
    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

    }, [form])

    const {height, width } = useWindowDimensions();
    const [dt, setDt]= useState('');
    const [dat, setDat] = useState(null);


    async function removeDuplicates(data) {
      let unique = data.reduce(function(a,b){
        if(a.indexOf(b) < 0) a.push(b);
        return a;
      },[]);
      return unique;
    };

    useEffect(() => {

      let d = new Date();
      let n = d.getFullYear();
      setDt(n);

      if(localStorage.getItem('session') === null || localStorage.getItem('session') === undefined)
        {
           
            navigate('/')
        }
      
        getText('http://apps.ratricloud.com/tabledata.txt')
        

        return () => {
            
        }
    },[])


    function getText(file){
      var request = new XMLHttpRequest();
      request.open('GET', file, true);
      request.send(null);
      request.onreadystatechange = function () {
          if (request.readyState === 4 && request.status === 200) {
              var type = request.getResponseHeader('Content-Type');
              if (type.indexOf("text") !== 1) {
                  //return request.responseText;
                  let obj = JSON.parse(request.responseText);
                  setDat(obj);
              }
          }
      }
  }

  const columns = [
    
    {
        title: 'Application Name',
        dataIndex: 'name',
        key: 'name', 
        width: '50%'      
    },
    {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',   
        width: '40%'          
    },    
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '10%',     
        render: (text, record) => (
          <a href={`${record.file}`}><CloudDownloadOutlined twoToneColor="red"/> &nbsp;&nbsp;Download</a>
        )

    },
];

    return (
        <>
        {/* <div style={{ backgroundImage:`url("/header-bg.png")`, color:'white', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>                
        &nbsp;&nbsp;<HomeOutlined />
        &nbsp;&nbsp;Download Center
        </div>     */}
        <Layout>         
           
           
            <Content style={{backgroundColor: 'white'}}> 
                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh',overflow:'auto',width:`${width-210}px`}}>
                    <InfiniteScroll
                        pageStart={0}                        
                        hasMore={true || false}
                        useWindow={false}
                    >
                     
                        <Table dataSource={dat} columns={columns} rowKey="id" pagination={false} scroll={{ x: 500}}>
                        </Table>
                      
                        
                    </InfiniteScroll>
                        
                    </div> 
                </Form>
            </Content> 
                   
        </Layout>
        <div style={{ textAlign: 'center', fontSize: '14px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>                
        {`©${dt} Managed by Ratri Cloud`}
        </div>   
       
        </>
    );
}

export default Dashboard
