import Dashboard from './containers/Dashboard/dashboard'



import React from "react";



const routes = {
    '/dashboard': () => <Dashboard />,
   /* '/user/:id': ({id}) => <Users id={id}/>,*/
   
}

export default routes
